
import { mixins, Options, Vue } from "vue-class-component";
import { CalculatePrice, QuantityPerItem, ThousandSeparator, UploadPath } from "@/mixins/utilities";
import PreviewImage from "@/components/images/PreviewImage.vue";
import { DeliveryType, OrderCartItemViewModel, OrderCartViewModel, OrderStatus } from "@/api-client";

@Options({
  components: { PreviewImage },
  props: {
    product: { default: {} },
    cart: { default: null },
    currency: {default: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    }}
  },
})
export default class OrderSummaryProductTile extends mixins(CalculatePrice, UploadPath, QuantityPerItem, ThousandSeparator) {
  cart: OrderCartViewModel = {
    id: "",
    items: [],
    countryId: "",
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: "",
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    additionalCostsConvertedTotal: 0,
    orderDocuments: [],
    isQuote: false,
    referenceNumber: '',
    deliveryAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    billingAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    customerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
    },
    quoteMessage: '',
    accessKey: '',
    allowedPaymentProviders: [],
    voucher: null,
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  };
  product: OrderCartItemViewModel = {
    id: "",
    price: 0,
    orderId: "",
    customizedProduct: {
      id: "",
      title: "",
      lastModifiedDate: "",
      productId: "",
      productSlug: "",
      name: "",
      code: "",
      canAddToStore: false,
      previewImageUrl: "",
      backPreviewImageUrl: "",
      customerCanUpdate: false,
      adminCanUpdate: false,
      orderReferenceNumber: "",
      enquiryReferenceNumbers: [],
      orderId: "",
      style: {
        id: "",
        name: "",
        code: "",
        previewImageUrl: "",
        frontShadowMaskUrl: "",
        backShadowMaskUrl: "",
        manufacturerReference: "",
        factoryId: "",
      },
      design: {
        id: "",
        name: "",
        code: "",
        imageUrl: "",
      },
      layers: [],
      items: [],
      extras: [],
    },
    quantities: [],
  };

  get productName() {
    return this.cart.storeId && this.product.customizedProduct.storeProductName ? this.product.customizedProduct.storeProductName : this.product.customizedProduct.name;
  }
}
