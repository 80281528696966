
import { mixins, Options } from "vue-class-component";
import PageNotFoundBanner from "@/components/misc/PageNotFoundBanner.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import { useMeta } from 'vue-meta'
import { SetMetaData } from "@/mixins/utilities";

@Options({
  components: { PageNotFoundBanner, Breadcrumbs, SetMetaData },
})
export default class PageNotFoundPage extends mixins(SetMetaData) {
  created() {
    const {meta} = useMeta({})
  
    this.setMetaData(meta, {}, '', `Page Not Found | `)
  }
}
