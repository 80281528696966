
import { CountryViewModel, DeliveryType, OrderCartDeliveryOptionViewModel, OrderCartViewModel, OrderStatus } from "@/api-client";
import { CalculatePrice, Country, Currency, FormatDate } from "@/mixins/utilities";
import { Carts, Countries } from "@/network/api";
import { store } from "@/store";
import { mixins, Options } from "vue-class-component";

@Options({
  props: {
    cartDelivery: { default: "" },
    currency: { default: {
        id: "",
        name: "",
        code: "USD",
        symbol: "",
        pricingMultiplier: 0,
        decimalPlaces: 2,
      }
    },
    storeCountry: { default: {
        id: '',
        name: ''
      }
    },
    upgradeDelivery: { default: false },
    cart: { default: null },
    accessKey: { default: undefined },
    isCustomerQuote: { default: false },
  },
  emits: ["updateDelivery", "updateCountry", "resetUpgradeDelivery"],
})
export default class CartDeliveryOptions extends mixins(CalculatePrice, Country, FormatDate) {
  cartDelivery = "";
  cart: OrderCartViewModel = {
    id: "",
    items: [],
    countryId: "",
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: "",
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    orderDocuments: [],
    isQuote: false,
    additionalCostsConvertedTotal: 0,
    referenceNumber: "",
    deliveryAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    billingAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    customerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
    },
    accessKey: "",
    allowedPaymentProviders: [],
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  };
  selectedCountry: any = {
    id: "",
    name: "",
    isDefault: false,
    code: "",
    deliveryTypes: [],
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
  };
  selectedDeliveryType: OrderCartDeliveryOptionViewModel = {
    deliveryTypeId: DeliveryType.Standard,
    deliveryTypeName: "",
    deliveryDays: 0,
    productionDays: 0,
    deliveryCost: 0.0,
  };
  // selectedDeliveryType = DeliveryType.Standard
  allCountries: Array<CountryViewModel> = [];
  deliveryOptions: Array<OrderCartDeliveryOptionViewModel> = [];
  upgradeDelivery = false;
  accessKey: string | undefined = undefined;
  storeCountry: {id: string, name: string } = {
    id: '',
    name: ''
  }

  get isAdminQuote() {
    if (this.cart.isQuote) {
      return true;
    }
    return false;
  }

  mounted() {
    if(this.cart.id) {
      this.loadInitialData();
    }

    this.$watch("queryCountry", () => {
      if(this.allCountries.length && this.storeCountry.id) {
        this.selectedCountry = this.allCountries.find((country: CountryViewModel) => {
          return this.storeCountry.id ? country.id === this.storeCountry.id : country.id === this.cart.countryId;
        });
      } else {
        this.selectedCountry = this.country();
      }
      this.getDefaultDelivery();
    });

    this.$watch("selectedCountry", () => {
      if (this.selectedCountry.id != this.country().id) {
        store.dispatch("location/changeLocation", { country: this.selectedCountry });
      }
    });

    this.$watch("selectedDeliveryType", () => {
      this.$emit("updateDelivery", this.selectedDeliveryType, this.selectedCountry);
    });

    this.$watch("upgradeDelivery", () => {
      if (this.upgradeDelivery) {
        this.deliveryOptions.forEach((option: any) => {
          if (option.deliveryTypeId === DeliveryType.Express) {
            this.selectedDeliveryType = option;
            this.$emit("resetUpgradeDelivery");
          }
        });
      }
    });

    this.$watch("cart", () => {
      if (this.cart.id) {
        this.loadInitialData();
      }
    });
  }

  get queryCountry() {
    if(this.allCountries.length && this.storeCountry.id) {
      return this.allCountries.find((country: CountryViewModel) => {
        return this.storeCountry.id ? country.id === this.storeCountry.id : country.id === this.cart.countryId;
      });
    }
    return this.country();
  }

  determineDelivery(type: any) {
    let deliveryType = {} as any;
    if (this.selectedCountry.id) {
      this.selectedCountry.deliveryTypes.forEach((item: any) => {
        if (item.deliveryTypeId === type.deliveryTypeId) {
          deliveryType = item;
        }
      });

      let date = new Date();
      let deliveryDays = deliveryType.deliveryDays;
      let totalDays = deliveryDays + type.productionDays;

      if (totalDays > 0) {
        date.setDate(date.getDate() + totalDays);
        return this.formatDate(date);
      } else {
        return "Contact us";
      }
    }
  }

  loadInitialData() {
    Countries.countriesGet().then((res) => {
      if (res.data.succeeded) {
        this.allCountries = res.data.resultData as Array<CountryViewModel>;

        this.selectedCountry = this.allCountries.find((country: CountryViewModel) => {
          return this.storeCountry.id ? country.id === this.storeCountry.id : country.id === this.cart.countryId;
        });
        this.getDefaultDelivery();
      }
    });
  }

  async getDeliveryOptions() {
    if (this.cart.id) {
      await Carts.cartsGetCartDeliveryOptionsIdGet(this.cart.id, this.selectedCountry.id, this.accessKey)
        .then((res) => {
          if (res.data.succeeded) {
            this.deliveryOptions = res.data.resultData as Array<OrderCartDeliveryOptionViewModel>;
            console.log("delivery options loaded", this.deliveryOptions);
          }
        })
        .catch((error) => {
          console.log(error);
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    }
  }

  getDefaultDelivery() {
    this.$emit("updateCountry", this.selectedCountry.id);
    this.getDeliveryOptions().then(() => {
      if (this.deliveryOptions.length) {
        let typeAvailable = this.deliveryOptions.filter((x) => x.deliveryTypeId === this.cart.deliveryTypeId).length > 0;
        if (typeAvailable) {
          this.deliveryOptions.forEach((type: OrderCartDeliveryOptionViewModel) => {
            if (this.cart.deliveryTypeId === type.deliveryTypeId) {
              this.selectedDeliveryType = type;
            }
          });
        }

        if ((!typeAvailable || this.cartDelivery === "") && this.deliveryOptions.length > 0) {
          this.selectedDeliveryType = this.deliveryOptions[0];
        }
      }
    });
  }
}
