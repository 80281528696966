
import { mixins, Options, Vue } from "vue-class-component";
import Spinner from "@/components/spinner/Spinner.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import HeadingBanner from "@/components/banners/HeadingBanner.vue";
import CartProductTile from "@/components/checkout-process/cart/CartProductTile.vue";
import CartSummary from "@/components/checkout-process/cart/CartSummary.vue";
import CheckoutBreadcrumbs from "@/components/checkout-process/CheckoutBreadcrumbs.vue";
import CartDeliveryOptions from "@/components/checkout-process/cart/CartDeliveryOptions.vue";
import { AdminOrders, Carts, Stores } from "@/network/api";
import { CountryViewModel, DeliveryType, OrderCartDeliveryOptionViewModel, OrderCartItemViewModel, OrderCartViewModel, OrderStatus, StoreStatus, StoreViewModel } from "@/api-client";
import { Country, Currency, QuantityPerItem } from "@/mixins/utilities";
import BasciPromptModal from "@/components/misc/BasicPromptModal.vue";
import { store } from "@/store";
import StatementBanner from "@/components/banners/StatementBanner.vue";
import { useMeta } from "vue-meta";
import { SetMetaData } from "@/mixins/utilities";

@Options({
  props: { 
    slug: { default: "profile-details" },
    id: { default: '' },
    accessKey: { default: '' }
  },
  components: { Spinner, HeadingBanner, Breadcrumbs, CartProductTile, CartSummary, CartDeliveryOptions, BasciPromptModal, StatementBanner, CheckoutBreadcrumbs },
  beforeRouteUpdate(to, from) {
    this.notCartStatus = false;
    
    if(to.params?.accessKey) {
      this.sharedCartId = to.params?.id;
    } else {
      this.sharedCartId = ''
    }

    if(to.name === 'StoreCart') {
      this.storeCartId = to.params?.id;
    } else {
      this.storeCartId = ''
    }

    this.loadInitialData();
  },
  beforeRouteLeave(to, from) {
    if(to.params.id !== from.params.id) {
      if(to.params?.accessKey) {
        this.sharedCartId = to.params?.id;
      } else {
        this.sharedCartId = ''
      }

      if(to.name === 'StoreCart') {
        this.storeCartId = to.params?.id;
      } else {
        this.storeCartId = ''
      }
      
      this.loadInitialData();
    }
  },
})
export default class CartPage extends mixins(QuantityPerItem, SetMetaData, Currency, Country) {
  //cartId = "4daea0dd-9370-4fa1-8e9b-c778915694c0";
  id= '';
  sharedCartId = '';
  cart: OrderCartViewModel = {
    id: "",
    storeId: null,
    items: [],
    countryId: "",
    isGift: false,
    deliveryTypeId: DeliveryType.Standard,
    orderStatusId: OrderStatus.Cart,
    currencyId: "",
    currencyPricingMultiplyer: 1,
    requiresCustomerApproval: true,
    additionalCostsConvertedTotal: 0,
    orderDocuments: [],
    isQuote: false,
    referenceNumber: '',
    deliveryAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    billingAddress: {
      id: "",
      addressLine1: "",
      area: "",
      country: "",
      postalCode: "",
    },
    customerContact: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
    },
    quoteMessage: '',
    accessKey: '',
    allowedPaymentProviders: [],
    voucher: null,
    paymentsTotal: 0,
    paymentsConvertedTotal: 0,
    customerContacts: []
  };
  summaryTotal: Array<number> = [];
  deliveryOptions: Array<OrderCartDeliveryOptionViewModel> = [];
  deliveryType: OrderCartDeliveryOptionViewModel = {
    deliveryTypeId: DeliveryType.Standard,
    deliveryTypeName: "",
    deliveryDays: 0,
    productionDays: 0,
    deliveryCost: 0.0,
  };
  expressDelivery = false;
  productIdToRemove: null | string = null;
  loading = false;
  accessKey: string | undefined = undefined;
  notCartStatus = false;
  openSizeSelectorFor: string | null = null;
  showCartSummary = true;
  storeCartId = '';
  store: StoreViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    countryId: "",
    countryName: "",
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: ""
  };

  get totalPrice() {
    let total = 0;
    let sum = this.summaryTotal.reduce((acc: any, curr: any) => {
      return acc + curr;
    }, total);
    return sum;
  }

  get cartId() {
    return store.getters["checkout/cartId"];
  }

  get isQuote() {
    if (this.cart.isQuote) {
      return true;
    }
    return false;
  }

  get shareLink() {
    if(this.cart.id) {
      let routeData = this.$router.resolve({name: 'Cart', params: {id: this.cart.id, accessKey: this.cart.accessKey}});
      return `${window.location.origin}${routeData.href}`
    }
    return ''
  }

  get cartCurrency() {
    // if(this.store.id) {
    //   return this.store.currency;
    // } 
    return this.currency();
  }

  created() {
    const { meta } = useMeta({});

    this.setMetaData(meta, {}, "", `${this.$route.name === 'StoreCart' ? "Online Store Cart" : this.isQuote ? "Quote" : "Cart"} | `);

    if(this.accessKey) {
      this.sharedCartId = this.id;
    } else {
      this.sharedCartId = ''
    }

    if(this.$route.name === 'StoreCart'){
      this.storeCartId = this.id;
    } else {
      this.storeCartId = "";
    }

    if (!this.storeCartId && (!this.id || !this.sharedCartId) && this.cartId) {
      this.openSizeSelectorFor = this.$route.query.sizesFor as string || null;
      this.$router.push({ name: "Cart", params: { id: this.cartId } });
    }

    if(!this.country()?.id) {
      store.dispatch("location/getDefaultSelection");
    }

    this.loadInitialData();
  }

  handleRemoveId(id: string, action = "save") {
    if (action === "save") {
      this.productIdToRemove = id;
    }

    if (action === "clear") {
      this.productIdToRemove = null;
    }
  }

  removeCartFromLocalStorage() {
    store.dispatch("checkout/refreshCart", {
      id: null,
      accessKey: this.accessKey || undefined
    });
    this.$router.push({name: 'Home'})
  }

  async removeProduct() {
    await store
      .dispatch("checkout/removeCartItem", {
        id: this.productIdToRemove,
        accessKey: this.accessKey || undefined
      })
      .then(async () => {
        window.scrollTo(0, 0);
        await this.loadInitialData();
        this.productIdToRemove = null;
        if (this.cart.items.length === 0) {
          this.$router.push({ name: "AllProductSelector" });
        }
      })
      .catch((error) => {
        console.log(error);
        console.error(error);
      });
    // CartItems.cartItemsIdDelete(this.productIdToRemove as string)
    // .then(() => {
    //   console.log('itemDeleted');

    // })
  }

  updateDelivery(selectedDeliveryType: OrderCartDeliveryOptionViewModel, selectedCountry: CountryViewModel) {
    if (this.cart.id) {
      this.deliveryType = selectedDeliveryType;
      this.cart.deliveryTypeId = this.deliveryType.deliveryTypeId;
      this.cart.countryId = selectedCountry.id || this.cart.countryId;
      this.cart.currencyId = selectedCountry.currency.id || this.cart.currencyId;

      if (this.cart.id && (['Cart', 'CartValidated', 'PreparingQuote', 'AmendQuote'].includes(this.cart.orderStatusId))) {
        this.updateCart();
      }
    }
  }

  updateDeliveryOptions(id: string) {
    if (this.cart.id) {
      this.getDeliveryOptions(id);
    }
  }

  async updateCart() {
    this.loading = true;
    await Carts.cartsUpdateCartIdPut(this.sharedCartId || this.storeCartId || this.cartId || this.id, this.accessKey || undefined, this.cart)
      .then((res) => {
        if (res.data.succeeded) {
          this.cart.additionalCostsConvertedTotal = res.data.resultData?.additionalCostsConvertedTotal as number
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
  }

  goToCheckout(checkValues = true) {
    let emptyValues = 0;

    if (checkValues) {
      this.cart.items.forEach((item: OrderCartItemViewModel) => {
        let quantities = item.quantities;
        quantities.forEach((quantity) => {
          quantity.extraCustomizations.forEach((extra) => {
            if (!extra.value) {
              emptyValues += 1;
            }
          });
        });
      });
    }

    if (emptyValues == 0) {
      this.updateCart().then(() => {
        window.dataLayer?.push({'event': 'begin_checkout'}) // GTM Event
        this.$router.push({ name: "Checkout", params: { id: this.sharedCartId || this.storeCartId || this.cartId || this.id, accessKey: this.accessKey || undefined } });
      });
    } else {
      let modalButton = document.getElementById("showMissingModal") as any;
      if(modalButton) {
        modalButton.click();
      }
    }

    //Carts.cartsUpdateCartIdPut(this.cartId, this.cart)
  }

  returnToOMS(cartId: string) {
    if (cartId) {
      let link = `${process.env.VUE_APP_ROOT_ADMIN}/orders/edit-order/${cartId}`;
      window.open(link, "_self");
    } else {
      this.$notify({ type: "error", text: "Redirect error", ignoreDuplicates: true, duration: -1 });
    }
  }

  async sendQuote(action: string) {
    window.scrollTo(0, 0);
    if (action == "send") {
      this.updateCart().then(() => {
        if (this.cart.customerContact) {
          this.loading = true;
          AdminOrders.adminOrdersSendOrderQuoteOrderQuoteIdPost(this.cartId, this.cart.customerContact.email, this.cart.customerContact.name, this.cart.customerContact.phoneNumber, this.cart.allowedPaymentProviders, this.cart.quoteMessage || undefined)
            .then(async (res) => {
              if (res.data.succeeded) {
                this.$notify({ type: "success", text: "Quote sent." });
                let oldCartId = this.cartId;
                await store
                  .dispatch("checkout/refreshCart", {
                    id: null,
                    //accessKey: this.accessKey
                  })
                  .then(() => {
                    this.returnToOMS(oldCartId);
                  });
              }
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              let errors = error.response.data.errors;
              errors.forEach((error: any) => {
                this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
              });
            });
        }
      });
    }

    if (action == "save") {
      this.updateCart().then(() => {
        this.$notify({ type: "success", text: "Quote saved.", ignoreDuplicates: true });
      })
    }

    if (action == "return") {
      this.loading = true;
      await store.dispatch("checkout/refreshCart", {
        id: null,
        //accessKey: this.accessKey
      })
      .then(() => {
        this.returnToOMS(this.cart.id);
        this.loading = false;
      });
      this.loading = false;
    }
  }

  async getStoreInfo() {
    if(this.cart.id && this.cart.storeId) {
    this.loading = true;
    await Stores.storesIdGet(this.cart.storeId)
      .then(async (res) => {
        if (res.data.succeeded) {
          this.store = res.data.resultData as StoreViewModel;
        }
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
        let errors = error.response.data.errors;
        errors.forEach((error: any) => {
          this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
        });
      });
    }
  }

  async loadInitialData() {
    if (this.sharedCartId || this.storeCartId || this.cartId || this.id) {
      this.loading = true;
      await store
        .dispatch("checkout/refreshCart", {
          id: this.sharedCartId || this.storeCartId || this.cartId || this.id,
          setStore: this.accessKey || this.storeCartId ? false : true,
          accessKey: this.accessKey || undefined
        })
        .then(async (res) => {
          if (res.data.succeeded) {

            this.cart = res.data.resultData;

            if (['Cart', 'CartValidated', 'PreparingQuote', 'AmendQuote'].includes(this.cart.orderStatusId)) {
              this.notCartStatus = false;
              this.summaryTotal = [];

              this.cart.items.forEach((product: any) => {
                this.summaryTotal.push(product.price * this.quantityPerItem(product));
              });

              if(this.cart.storeId) {
                await this.getStoreInfo();
              }

              await this.getDeliveryOptions(this.store.countryId || this.cart.countryId);

              if(this.$route.query.accepted) {
                let modalButton = document.getElementById("showQuoteToCart") as any;
                if(modalButton) {
                  modalButton.click();
                }
                this.$router.replace({name: 'Cart', params: {id: this.cart.id}})
              }

              if(this.openSizeSelectorFor) {
                const refs = this.$refs as any;
                if(refs) {
                  const sizeToOpen = refs[this.openSizeSelectorFor as any]; 
                  if (sizeToOpen) {
                    this.openSizeSelectorFor = null;
                    sizeToOpen[0].showModal = true;
                  }
                }
              }
            } else {
              this.notCartStatus = true;
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    }
  }

  async getDeliveryOptions(id: string) {
    if (this.sharedCartId || this.storeCartId || this.cartId) {
      await Carts.cartsGetCartDeliveryOptionsIdGet(this.sharedCartId || this.storeCartId || this.cartId || this.id, id, this.accessKey || undefined)
        .then((res) => {
          if (res.data.succeeded) {
            this.deliveryOptions = res.data.resultData as Array<OrderCartDeliveryOptionViewModel>;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          let errors = error.response.data.errors;
          errors.forEach((error: any) => {
            this.$notify({ type: "error", text: error.friendlyMessage, ignoreDuplicates: true, duration: -1 });
          });
        });
    }
  }

  copyLink() {
    // Write to the clipboard
    if(this.shareLink) {
      let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
      if (!isMobile) {
        navigator.clipboard.writeText(this.shareLink);
        this.$notify({ type: "success", text: "The cart link has been copied to your clipboard." });
      }
      else {
        const sharing = async () => {
          if (navigator.share) {
            await navigator.share({
              title: 'CLIFTON | Truly Custom Clothing',
              text: 'Access my CLIFTON cart!',
              url: this.shareLink
            })
            .then(() => {
                this.$notify({ type: "success", text: "Cart link copied to clipboard." });
              }
            )
            .catch(err => {
                if (err.toString().indexOf("cancel")) {
                  this.$notify({ type: "warning", text: "Copy of cart link cancelled." });
                }
                else {
                  this.$notify({ type: "error", text: `Error: ${err}` });
                }
              }
            );
          }
          else {
            navigator.clipboard.writeText(this.shareLink);
            this.$notify({ type: "success", text: "The cart link has been copied to your clipboard." });
          }
        }

        sharing();
      }
    }
  }
}
