
import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import { store } from "@/store";
import { SocialProviders } from "@/store/modules/auth";
import { CallbackTypes } from "vue3-google-login";

@Options({
  components: { Form, Field, ErrorMessage },
  props: {},
  emits: ["login", "guest"]
})
export default class CheckoutLoginComponent extends Vue {
  socialEnabled = true;
  user = {
    email: "",
    password: "",
  };

  guestUser = {
    email: "",
  };

  async login() {
    await store
      .dispatch("auth/login", {
        email: this.user.email,
        password: this.user.password,
      })
      .then(() => {
          this.$emit("login")
      })
      // .catch((err) => {
      //   console.log(err);
      // });
  }

  guestSignIn(values: any) {
    this.$emit("guest", values.Email)
    console.log(values.Email);
  }

  fbResponse(data: any) {
    if(data?.authResponse) {
    console.log("FB response", data);
    store
      .dispatch("auth/socialAuth", {
        provider: SocialProviders.Facebook,
        token: data.authResponse.accessToken,
      })
    }
  }

  googleLogin: CallbackTypes.CredentialCallback = (response) => {
    if(response?.credential) {
      store
      .dispatch("auth/socialAuth", {
        provider: SocialProviders.Google,
        token: response.credential,
      })
    }
  }

  appleSuccess(data: any) {
    if(data?.authorization?.code) {
      console.log(data);
      var fName = "";
      var lName = "";

      if (data.userData != null) {
        fName = data.userData.name.firstName;
        lName = data.userData.name.lastName;
      }

      store
      .dispatch("auth/socialAuth", {
        provider: SocialProviders.Apple,
        token: data.authorization.code,
        firstname: fName,
        lastname: lName,
      })
    }
  }
}
